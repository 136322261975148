.ComingSoon {
  position: relative;
  opacity: 0.6;
  border-radius: 10px;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(2px) grayscale(70%) opacity(80%);
  }

  & > &__text {
    z-index: 9;
    position: absolute;
    // for center
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    top: 5px;
    right: 5px;
    padding: 0.25rem 0.75rem;
    background-color: rgba(85, 85, 85, 0.2);
    border-radius: 10px;
  }
}
