.infra-item {
  text-align: center;
  font-size: 28px;
  /* color: #FFFFFF; */
}
.infra-txt {
  text-align: center;
  font-size: 14px;
  /* color: #FFFFFF; */
}
.single-card {
  cursor: pointer;
}
.single-card:hover {
  background-color: rgba(75, 85, 140, 0.2);
  border: 1px solid;
}
.item-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  transition: background-color 300ms ease-out;
  border: 3px solid #f2f2f2;
}
.item-box:hover {
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border-color: #857efd;
  color: #6b63ff;
}
