.editOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 0.75rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--active {
    background-color: rgba(100, 100, 100, 0.1);
  }
}

.floorSectionLabel,
.deleteIcon,
.editIcon {
  // color: #d4d4d8;
  transition-duration: 0.3s;
  transition-property: color, transform;
}

.floorSectionLabel {
  padding: 1rem 0.5rem;
}

.editIcon,
.deleteIcon {
  padding: 0.75em;
}

.deleteIcon:hover {
  color: rgba(red, 0.8);
}

.deleteIcon:hover,
.editIcon:hover {
  transform: scale(1.2);
}
