.Icon {
  // display: flex;
  // justify-content: center;
  // align-items: center;

  &.clickable {
    cursor: pointer;
  }

  &.xs {
    font-size: 12px;
  }

  &.sm {
    font-size: 16px;
  }

  &.md {
    font-size: 20px;
  }

  &.lg {
    font-size: 24px;
  }

  &.xl {
    font-size: 26px;
  }

  &.xxl {
    font-size: 30px;
  }

  &.xxxl {
    font-size: 36px;
  }

  &.xxxxl {
    font-size: 42px;
  }

  &.text-primary {
    background-image: linear-gradient(310deg, #7928ca, #ff0080);
  }

  &.text-info {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
  }

  &.text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.text-gradient-danger,
  &.text-gradient-dark,
  &.text-gradient-info,
  &.text-gradient-primary,
  &.text-gradient-success,
  &.text-gradient-warning {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    z-index: 1;
  }

  &.text-gradient-primary {
    background-image: linear-gradient(310deg, #7928ca, #ff0080);
  }

  &.text-gradient-info {
    background-image: linear-gradient(310deg, #2152ff, #21d4fd);
  }

  &.text-gradient-success {
    background-image: linear-gradient(310deg, #17ad37, #c1e823);
  }

  &.text-gradient-warning {
    background-image: linear-gradient(310deg, #f53939, #fbcf33);
  }

  &.text-gradient-danger {
    background-image: linear-gradient(310deg, #d60808, #ff6690);
  }

  &.text-gradient-dark {
    background-image: linear-gradient(310deg, #141727, #3a416f);
  }
}
