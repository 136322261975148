.GatewayConnectView {
  width: 100%;

  &__container {
    width: 100%;
    height: 100%;
    // padding: 0.5rem;
    margin-bottom: 0 !important;
  }
}
