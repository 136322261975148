hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.35;
}

hr:not([size]) {
  height: 1px;
}

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
}

hr.vertical.light {
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff, hsla(0, 0%, 100%, 0));
}

hr.vertical.dark {
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.vertical.gray-light {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.light {
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff, hsla(0, 0%, 100%, 0));
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.horizontal.gray-light {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
}
