/* CSSTransition classes  */

:root {
  --speed: 200ms;
}

.SideBarSelect {
  overflow: hidden;
  transition: height var(--speed) ease;
}

.Menu--primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.Menu--primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.Menu--primary-exit {
  position: absolute;
}
.Menu--primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.Menu--secondary-enter {
  transform: translateX(110%);
}
.Menu--secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
// .Menu--secondary-exit {
// }
.Menu--secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}
