.table-responsive {
  overflow-x: hidden !important;
}

#portal {
  z-index: 99999999999999 !important;
  position: absolute;
  top: 0;
  left: 0;
}

#root-overlay {
  z-index: 99 !important;
  position: absolute;
  top: 0;
  left: 0;
}

/* TODO: move to a proper location */

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
}

hr.vertical.light {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0),
    #fff,
    hsla(0, 0%, 100%, 0)
  );
}

hr.vertical.dark {
  background-image: linear-gradient(
    180deg,
    transparent,
    rgba(0, 0, 0, 0.4),
    transparent
  );
}

hr.vertical.gray-light {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.1),
    transparent
  );
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.light {
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    #fff,
    hsla(0, 0%, 100%, 0)
  );
}

hr.horizontal.dark {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.4),
    transparent
  );
}

hr.horizontal.gray-light {
  background-image: linear-gradient(
    90deg,
    transparent,
    rgba(0, 0, 0, 0.1),
    transparent
  );
}
