// .SubjectInfo {
//   background-color: #aaa !important;
// }

.SubjectImageCard {
  height: 10rem;
  width: 110%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  &__cover {
    height: 100%;
    width: 50%;
    border-radius: 15px;
    background-image: linear-gradient(to right, blue, rgba(0, 0, 0, 0));
  }

  &__cover--female {
    background-image: linear-gradient(
      to right,
      rgba(#e14eca, 0.5),
      rgba(0, 0, 0, 0)
    );
  }

  &__image {
    --diameter: 6.5rem;
    height: var(--diameter);
    width: var(--diameter);
    border-radius: 100%;
    background-color: #212529;
    border: 3px solid rgba(#212529, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.SubjectInfoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
