.ActionPanel {
  &__row {
    justify-content: space-between;
    align-items: center;
  }

  &__select {
    min-width: 200px;
  }
}
