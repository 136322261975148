.status {
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &--active {
    background-color: green;
  }

  &--notactive {
    background-color: red;
  }
}
