.dzu-dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 120px;
    overflow: hidden !important;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    transition: all .15s linear;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-right: 15px;
}
.caption-text{
    margin-top: 15px;
}
thead {position: -webkit-sticky; position: sticky; top: 0px; z-index: 100;}
  