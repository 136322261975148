.EditMaps__mainContainer {
  padding-right: 2rem;
}

.OriginalMap {
  width: 50px;
  height: 50px;
  object-fit: contain;
  // background-color: transparent;
}

.MapContainer {
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.EditMaps {
  &__header {
    padding: 2rem 1rem;
  }

  &__headerLabel {
    padding: 0 1.25rem;
    text-transform: capitalize;
  }

  &__popup {
    width: 20rem;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -10%);
    z-index: 99999;
  }
}

// Style overrides
// .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
// background-color: transparent;
// }
