.AlertBell {
  &__label,
  &__circle,
  &__icon {
    color: red !important;
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @keyframes grow-fade {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(1.5);
    }
  }

  &__circle {
    --diameter: 3rem;
    height: var(--diameter);
    width: var(--diameter);
    display: grid;
    place-items: center;
    border: 2px solid red;
    border-radius: 100%;
    margin: 1rem;
    position: relative;

    &::after,
    &::before {
      content: "";
      --diameter: 3rem;
      height: var(--diameter);
      width: var(--diameter);
      border: 2px solid red;
      border-radius: 100%;
      position: absolute;
    }

    &--animate {
      &::after,
      &::before {
        animation: grow-fade 1200ms ease-out infinite forwards;
      }
      &::before {
        animation-delay: 600ms;
      }
    }
  }
}
