.addButtDiv{
    float: right;
}
.addButt{
    float: right !important;
}
.tempButt{
    float: right !important;
    margin-top: 23px !important;
}
.createButtDv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-autosuggest__container, .react-autosuggest__input{
    width: 100% !important;
    height: auto;
}
.react-autosuggest__input{
    height: 40px !important;
}
.heading-text{
    margin-left: 15px;
}
.caption-text{
    margin-top: 15px;
}
.policy-table{
    height: 85vh !important;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
}
.autosuggest-item-table{
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 95px;
}
.suggest-text{
    color: #FFFFFF !important;
    font-size: 16px !important;
    text-align: center !important;
    font-weight: 600;
}
.suggest-text-dv{
    float: left;
    align-content: center;
    position: relative;
    top: 15px;
}
.suggest-item{
    float: left;
    height: auto;
    padding: 5px;
}
.remove-icon{
    color: #FFFFFF !important;
}
.remove-icon-dv{
    float: right;
    position: relative;
    left: 15px;
}
thead {position: -webkit-sticky; position: sticky; top: 0px; z-index: 100;}
.tab-content .tab-pane {
    color: rgba(15, 11, 11, 0.5) !important;
}