.AppLoader {
  position: fixed;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;

  &__container {
    max-height: 50vh;
    max-width: 50vw;
    height: 300px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__centerGraphics {
    padding: 1rem;
  }

  #lower,
  #upper {
    position: absolute;
    animation: signalLoad 1.2s infinite;
  }

  #lower {
    animation-delay: 100ms;
  }

  #upper {
    animation-delay: 500ms;
  }

  @keyframes signalLoad {
    0% {
      opacity: 0;
      top: 2px;
    }

    50% {
      opacity: 1;
      top: 0;
    }

    100% {
      opacity: 0;
      top: 2px;
    }
  }
}
