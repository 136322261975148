.ReactTable .rt-tbody .rt-tr {
  border: none !important;
}

.ReactTable {
  border: none !important;
  transition: height 300ms ease-out;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow) {
  border-radius: 10px;
  transition-property: background-color;
  transition-duration: 300ms;
  transition-timing-function: ease-out;
  padding: 0 0.5rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.ReactTable.-highlight .rt-thead .rt-tr {
  border-radius: 10px;
  padding: 0.5rem;
}

.ReactTable .rt-thead.-filters {
}

.NoDataComponent__wrap {
}
