.leftalign{
    float: left;
}
.rightalign{
    float: right;
}
.form-check input[type="checkbox"], .radio input[type="radio"] {
    position: absolute;
    opacity: 1 !important;
    visibility: visible !important;
}