.RecentAnnouncements {
  cursor: pointer;
}

.icon-container {
  padding-right: 16px;
}
.border-radius-md {
  border-radius: 0.5rem;
}
.bg-info-soft {
  background: rgba(33, 82, 255, 0.03);
}
.icon-shape {
  width: 48px;
  height: 48px;
  background-position: 50%;
  border-radius: 0.75rem;
  padding: 1rem;
}
.text-gradient.text-primary {
  background-image: linear-gradient(310deg, #7928ca, #ff0080);
}

.text-gradient.text-info {
  background-image: linear-gradient(60deg, #26c6da, #00acc1);
}

.text-gradient.text-success {
  background-image: linear-gradient(60deg, #66bb6a, #43a047);
}

.text-gradient.text-warning {
  background-image: linear-gradient(60deg, #ffa726, #fb8c00);
}

.text-gradient.text-danger {
  background-image: linear-gradient(310deg, #d60808, #ff6690);
}

.text-gradient.text-dark {
  background-image: linear-gradient(310deg, #141727, #3a416f);
}
.text-sm {
  line-height: 1.5;
}

.text-xs {
  line-height: 1.25;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-xxs {
  font-size: 0.65rem !important;
}
.shadow {
  box-shadow: 0 0.25rem 0.375rem -0.0625rem hsla(0, 0%, 8%, 0.12), 0 0.125rem 0.25rem -0.0625rem hsla(0, 0%, 8%, 0.07) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.icon {
  fill: currentColor;
  stroke: none;
}
