.FormNarrator {
  height: 2.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
}

.FormNarrator__text {
  text-align: center;
}
