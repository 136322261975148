.AlertModal {
  // z-index: 9999999;
  // top: 0;
  // position: fixed;

  & > .row {
    margin-left: 0;
    margin-right: 0;
  }

  &__coverModal {
    height: 80%;
    max-height: 92vh;
    padding: 0.5rem;
    max-width: 65vw;
    flex: 1;
    overflow-y: auto;
  }

  &__wrapperCol {
    display: flex;
    align-items: center;
  }

  &__wrapper {
    height: 100%;
    width: 100vw;
    display: flex;
    align-items: center;
  }

  &__sectionCards {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__bottomPanels {
    max-height: 18rem;
    overflow-y: auto;
  }
}

@keyframes fade-in-out {
  from {
    border-color: #ff2b55;
  }
  to {
    border-color: white;
  }
}

.AlertModal__coverModal {
  background-color: white;
  border: 20px solid white;

  &--alert {
    animation: fade-in-out 1200ms ease-out infinite forwards;
  }
}

.AlertModal__avatar {
  --diameter: 200px;
  width: var(--diameter);
  height: var(--diameter);
  border: 5px solid red;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.AlertModal__floorSection {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  --side: 280px;
  height: var(--side);
  width: calc(var(--side) * 1.25);
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #c8cacb;
  position: relative;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.AlertModal__content {
  height: 90%;
}
