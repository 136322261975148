$red: #e91e63;
$green: #56cb5f;

.timelineBox {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 1rem auto;
}

.timeline__date {
  white-space: nowrap;
  font-size: 0.8rem;
  color: #595959 !important;
  line-height: 1rem;
}

.timelineBody {
  //   max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  .timeline {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    z-index: 2;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      background: #ccc9c9;
      top: 0;
      right: auto;
      left: 42px;
      height: 100%;
      z-index: -1;
    }
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 2rem;
      position: relative;
    }
    .timelineDot {
      height: 1.6rem;
      width: 1.6rem;
      background: #fff;
      border-radius: 2rem;
      flex: 0 0 1.5rem;
      margin-right: 2rem;
      margin-top: 6px;
      margin-left: 2rem;
      border: 2px solid #1c8cf7;
      &::after {
        content: "";
        position: absolute;
        top: 14px;
        left: 39px;
        height: 10px;
        width: 10px;
        background: #1c8cf7;
        border-radius: 50%;
      }
    }
    .timelineDate {
      font-size: 1rem;
      flex: 0 0 20px;
      padding: 0.2rem;
      line-height: 1.2rem;
    }
    .timelineWork {
      // font-family: inherit;
      font-size: 1rem;
      margin-left: auto;
      flex: 0 0 200px;
      small {
        display: block;
        //   color: #bdbdbd;
      }
    }
  }
}
