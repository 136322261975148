.FormHolder {
  &__infoContainer {
    // padding: 2rem 1rem;
  }

  &__img {
    padding: 1rem;
  }

  &__leftCol {
    border-right: 1px solid rgba(232, 233, 235, 0.66);
  }

  &__desc {
    text-align: justify !important;
  }
}
