.FloorSurvillence {
  height: 100%;
  width: 100%;

  & .card {
    height: calc(100% - 30px);
  }

  &__CardHeader {
    z-index: 1 !important;
    padding: 0.75rem 2.25rem !important;
  }

  &__CardBody {
    z-index: 0 !important;
  }

  &__Actions {
    min-width: 500px;
    width: 45%;
    margin: -1rem 0;
  }
}
