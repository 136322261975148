.TagView {
  width: 100%;

  &__container {
    width: 25em;
    height: 100%;
    // padding: 1rem;
    // margin: 2em;
  }

  &--revert {
    z-index: 999;
  }
}

// .popover {
//   max-width: 600px !important;
// }

body.white-content .popover-default {
  background-color: white;
}
