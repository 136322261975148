.MenuItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;
  margin-bottom: 0.75rem;
  color: #d4d4d8;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--active {
    background-color: rgba(100, 100, 100, 0.1);
  }

  &__trailing {
    font-size: 16px;
    font-weight: 500;
    margin-left: 0.5rem;
  }

  &__trailing i {
    margin-top: 4px;
  }
}

.SideMenu {
  padding: 2rem 1rem;

  &__header {
    padding: 0 1.25rem;
    text-transform: capitalize;
  }

  &__headerLabel i {
    margin-top: -3px;
  }
}
