.PresenceInsider {
  &__legends {
    height: 20px;
    width: 20px;
    border-radius: 5px;
    padding: 0.25rem;
  }
  &__percentages {
    min-width: 5ch;
  }
}
