.SubjectView {
  width: 100%;

  &__name > span {
    border-bottom: 1px dashed #1d8cf8;
    padding-bottom: 2px;
  }

  &__container {
    width: 100%;
    height: 100%;
    // padding: 0.5rem;
    margin-bottom: 0 !important;
  }
}

.popover {
  max-width: 600px !important;
}

body.white-content .popover-default {
  background-color: white;
}
