.ProfileInfo {
  &__infoBlock {
    // border-left: 1px solid #dddddd;
  }

  &__main {
    min-width: 500px;
  }

  &__infoBlock:first-child {
    border-left: none;
  }

  &__cardAvatarContainer {
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 0.75rem;
    height: 74px !important;
    width: 74px !important;
    transition: all 0.2s ease-in-out;
  }
}
