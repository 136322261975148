.OnboardingWidget {
  // max-height: 200px;
  position: relative;
  cursor: pointer;
  & h3 {
  }
  & .progress {
    height: 1.4rem;
    box-shadow: none;
  }
  & p {
    font-size: 1.15rem;
    font-weight: 300;
  }
  &__ImageContainer {
    // max-height: 200px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    height: 250px;
    text-align: center;
  }
}
